import React from "react";
import DashboardVisibilityToggle from "./AMLETDashboardVisibilityToggle";
import { UMLC_BASE_URLS } from "../../constants/amlet_constants";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const UMLC_BASE_URL = isDev? UMLC_BASE_URLS["dev"]:UMLC_BASE_URLS["prod"];

export const ColumnDefinitions = [
    {
        id: "modelType",
        header: "Model Type",
        cell: item => item.modelType,
        ariaLabel: createLabelFunction('modelType'),
        sortingField: "modelType",
        width: 300,
        minWidth: 50,
        sortingComparator: (a, b) => (a.modelType || '').localeCompare(b.modelType || '')
    },
    {
        id: "modelName",
        header: "Model Name",
        cell: item => <a href={UMLC_BASE_URL+item.modelName}>{item.modelName.split(":")[3]}</a>,
        ariaLabel: createLabelFunction('modelName'),
        sortingField: "modelName",
        width: 300,
        minWidth: 50,
        sortingComparator: (a, b) => (a.modelName || '').localeCompare(b.modelName || ''),
        isRowHeader: true
    },
    {
        id: "dataset",
        header: "Dataset Name",
        cell: item => <a href={UMLC_BASE_URL+item.dataset}>{item.dataset.split(":")[3]}</a>,
        ariaLabel: createLabelFunction('dataset'),
        sortingField: "dataset",
        width: 300,
        minWidth: 50,
        sortingComparator: (a, b) => (a.dataset || '').localeCompare(b.dataset || '')
    },
    {
        id: "dashboardVisibility",
        header: "Dashboard Visibility",
        cell: item => <DashboardVisibilityToggle model={item}/>,
        ariaLabel: createLabelFunction('dashboardVisibility'),
        width: 200,
        minWidth: 50,
        sortingComparator: (a, b) => (a.dashboardVisibility || '').localeCompare(b.dashboardVisibility || '')
    },
    {
        id: "modelAuthor",
        header: "Model Author",
        cell: item => item.modelAuthor,
        ariaLabel: createLabelFunction('modelAuthor'),
        sortingField: "modelAuthor",
        width: 200,
        minWidth: 50,
        sortingComparator: (a, b) => (a.modelAuthor || '').localeCompare(b.modelAuthor || '')
    },
    {
        id: "roc_auc",
        header: "ROC AUC",
        cell: item => item.roc_auc? Number(item.roc_auc).toFixed(4) : '-',
        ariaLabel: createLabelFunction('roc_auc'),
        sortingField: "roc_auc",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.roc_auc || 0) - Number(b.roc_auc || 0)
    },
    {
        id: "auc",
        header: "AUC",
        cell: item => item.auc? Number(item.auc).toFixed(4) : '-',
        ariaLabel: createLabelFunction('auc'),
        sortingField: "auc",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.auc || 0) - Number(b.auc || 0)
    },
    {
        id: "average_precision",
        header: "Average Precision",
        cell: item => item.average_precision? Number(item.average_precision).toFixed(4) : '-',
        ariaLabel: createLabelFunction('average_precision'),
        sortingField: "average_precision",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.average_precision || 0) - Number(b.average_precision || 0)
    },
    {
        id: "ap",
        header: "Average Precision",
        cell: item => item.ap? Number(item.ap).toFixed(4) : '-',
        ariaLabel: createLabelFunction('ap'),
        sortingField: "ap",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.ap || 0) - Number(b.ap || 0)
    },
    {
        id: "neg_root_mean_squared_error",
        header: "Neg. Root Mean Squared Error",
        cell: item => item.neg_root_mean_squared_error? Number(item.neg_root_mean_squared_error).toFixed(4) : '-',
        ariaLabel: createLabelFunction('neg_root_mean_squared_error'),
        sortingField: "neg_root_mean_squared_error",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.neg_root_mean_squared_error || 0) - Number(b.neg_root_mean_squared_error || 0)
    },
    {
        id: "neg_mean_absolute_percentage_error",
        header: "Neg. Mean Absolute Percentage Error",
        cell: item => item.neg_mean_absolute_percentage_error ? Number(item.neg_mean_absolute_percentage_error).toFixed(4) : '-',
        ariaLabel: createLabelFunction('neg_mean_absolute_percentage_error'),
        sortingField: "neg_mean_absolute_percentage_error",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.neg_mean_absolute_percentage_error || 0) - Number(b.neg_mean_absolute_percentage_error || 0)
    },
    {
        id: "neg_root_mean_squared_log_error",
        header: "Neg. Root Mean Squared Log Error",
        cell: item => item.neg_root_mean_squared_log_error ? Number(item.neg_root_mean_squared_log_error).toFixed(4) : '-',
        ariaLabel: createLabelFunction('neg_root_mean_squared_log_error'),
        sortingField: "neg_root_mean_squared_log_error",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.neg_root_mean_squared_log_error || 0) - Number(b.neg_root_mean_squared_log_error || 0)
    },
    {
        id: "neg_log_loss",
        header: "Negative Log Loss",
        cell: item => item.neg_log_loss ? Number(item.neg_log_loss).toFixed(4) : '-',
        ariaLabel: createLabelFunction('neg_log_loss'),
        sortingField: "neg_log_loss",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.neg_log_loss || 0) - Number(b.neg_log_loss || 0)
    },
    {
        id: "logloss",
        header: "Log Loss",
        cell: item => item.logloss ? Number(item.logloss).toFixed(4) : '-',
        ariaLabel: createLabelFunction('logloss'),
        sortingField: "logloss",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.logloss || 0) - Number(b.logloss || 0)
    },
    {
        id: "balanced_accuracy",
        header: "Balanced Accuracy",
        cell: item => item.balanced_accuracy ? Number(item.balanced_accuracy).toFixed(4) : '-',
        ariaLabel: createLabelFunction('balanced_accuracy'),
        sortingField: "balanced_accuracy",
        width: 120,
        minWidth: 50,
        sortingComparator: (a, b) => Number(a.balanced_accuracy || 0) - Number(b.balanced_accuracy || 0)
    },
    {
        id: "metric1",
        header: "Metric 1",
        cell: item => getSafeValue(item.metric1) ,
        ariaLabel: createLabelFunction('metric1'),
        sortingField: "metric1",
        width: 200,
        minWidth: 50,
        sortingComparator: (a, b) => (a.metric1 || '').localeCompare(b.metric1 || '')
    },
    {
        id: "metric2",
        header: "Metric 2",
        cell: item => getSafeValue(item.metric2) ,
        ariaLabel: createLabelFunction('metric2'),
        sortingField: "metric2",
        width: 200,
        minWidth: 50,
        sortingComparator: (a, b) => (a.metric2 || '').localeCompare(b.metric2 || '')
    },
    {
        id: "metric3",
        header: "Metric 3",
        cell: item => getSafeValue(item.metric3) ,
        ariaLabel: createLabelFunction('metric3'),
        sortingField: "metric3",
        width: 200,
        minWidth: 50,
        sortingComparator: (a, b) => (a.metric3 || '').localeCompare(b.metric3 || '')
    },
    {
        id: "createdAt",
        header: "Created At",
        cell: item => getSafeValue(item.createdAt),
        ariaLabel: createLabelFunction('createdAt'),
        width: 300,
        minWidth: 50,
        sortingField: "createdAt",
        sortingComparator: (a, b) => a.createdAt.valueOf() - b.createdAt.valueOf(),
    },
    {
        id: "experimentId",
        header: "Experiment Id",
        cell: item => <a href={UMLC_BASE_URL+item.experimentId}>{item.experimentId.split(":")[3]}</a>,
        ariaLabel: createLabelFunction('experimentId'),
        sortingField: "experimentId",
        width: 300,
        minWidth: 50,
        sortingComparator: (a, b) => (a.experimentId || '').localeCompare(b.experimentId || '')
    },
    {
        id: "region",
        header: "Region",
        cell: item => getSafeValue(item.region),
        ariaLabel: createLabelFunction('region'),
        sortingField: "region",
        width: 100,
        minWidth: 50,
        sortingComparator: (a, b) => stringComparator(a, b, 'region')
    }
];

// Helper function to handle empty values
const getSafeValue = (value) => {
    if (value === null || value === undefined || value === '' || value === ' ') {
        return '-';
    }
    return value;
};

// Helper function for string comparisons
const stringComparator = (a, b, key) => {
    const aValue = getSafeValue(a[key]);
    const bValue = getSafeValue(b[key]);


    if (!aValue && !bValue) return 0;

    if (!aValue) return 1;
    if (!bValue) return -1;

    return aValue.toString().localeCompare(bValue.toString());
};

export const getMatchesCountText = ({count}) => {
    return count === 1 ? `1 match` : `${count} matches`;
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: pageNumber => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
};

const pageSizePreference = {
    title: 'Select page size',
    options: [
        { value: 10, label: '10 experiments' },
        { value: 20, label: '20 experiments' },
    ],
};

function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
        const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
        return `${columnName}, ${sortState}.`;
    };
}

const contentDisplayPreference = {
    title: 'Column preferences',
    description: 'Customize the columns visibility and order.',
    options: ColumnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'experimentId' })),
};

export const collectionPreferencesProps = {
    pageSizePreference,
    contentDisplayPreference,
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
};

